import Macy from 'macy';
import fslightbox from 'fslightbox';

if(document.getElementById("macy")){
    var macy = Macy({
        container: '#macy',
        trueOrder: false,
        waitForImages: false,
        margin: 12,
        columns: 4,
        breakAt: {
            1200: 3,
            520: 2,
            400: 1
        }
    });
}